<div class="mt-3 shadow">
	<div *ngIf="tattoo" class="card">
		<div class="card-header bg-gradient-primary text-white">
			<h2 class="card-title text-center">Details for tattoo #{{tattoo.id}}</h2>
		</div>
		<div class="card-body">
			<br/>
			<div class="row row-cols-2 row-cols-md-2">
				<div class="col">
					<div class="card ">
						<div class="card-header">
							<h5><i class="fa fa-info-circle"></i> Tattoo information</h5>
						</div>
						<div class="card-body text-left">
							<i class="fa fa-fw fa-user"></i> Tattoo by artist
							<a [routerLink]="['/artist', tattoo.artist.id]">{{tattoo.artist | artistname}}</a> <br/>
							<i class="fa fa-fw fa-calendar"></i>    {{tattoo.date | formatdatetime}} <br/><br/>

							<span *ngIf="tattoo.status === 'FINISHED' || tattoo.status==='CLOSED'">
								<i class="fa fa-fw fa-clock-o"></i> Session from <b>{{tattoo.sessionStart | formattime}}</b>
								to <b>{{tattoo.sessionEnd | formattime}}</b> <br/>
							</span>

							<span *ngIf="tattoo.position !== null">
								<i class="fa fa-fw fa-bullseye"></i> Position: {{tattoo.position}} <br/>
							</span>
							<span *ngIf="tattoo.description !== null">
								<i class="fa fa-fw fa-quote-right"></i> Description: <i>"{{tattoo.description}}"</i> <br/>
							</span>
						</div>
						<div class="card-footer">
							<span class="text-muted">
								Booked by
								<a [routerLink]="['/user', tattoo.user.id]">{{tattoo.user.name}}</a>
								<span *ngIf="tattoo.source">
									({{tattoo.source}})
								</span>
							</span>
						</div>
					</div>

					<div class="card mt-4" *ngIf="tattoo.customer">
						<div class="card-header">
							<h5><i class="fa fa-user"></i> Customer</h5>
						</div>
						<div class="card-body">
							<div>
								<i class="fa fa-fw fa-user"></i>
								&nbsp;<i>{{tattoo.customer.name}}</i>
								<span class="text-muted"> - {{tattoo.customer.sex}}</span><br/>

								<span *ngIf="tattoo.customer.email">
									<i class="fa fa-fw fa-at"></i>&nbsp;
									<a href="mailto: {{tattoo.customer.email}}">{{tattoo.customer.email}}</a><br/>
								</span>
								<span *ngIf="tattoo.customer.phone">
									<i class="fa fa-fw fa-phone"></i>
									&nbsp;<a href="callto: {{tattoo.customer.phone}}">{{tattoo.customer.phone}}</a>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="col">
					<div class="card ">
						<div class="card-header">
							<h5><i class="fa fa-money"></i> Financial</h5>
						</div>
						<div class="card-body">
							<i class="fa fa-fw fa-euro"></i> Price: <strong>{{tattoo.price | currency:"EUR"}} <span *ngIf="tattoo.priceRange">-
							{{tattoo.priceRange | currency:"EUR"}}</span></strong><br/>
							<i class="fa fa-fw fa-calculator"></i> Pricing method: {{tattoo.pricingMethod | titlecase}}<br/>
							<span *ngIf="tattoo.order">
								<i class="fa fa-fw fa-file-o"></i>&nbsp;<a [routerLink]="['/order', tattoo.order.id]">Order #{{tattoo.order.id}}</a>
							</span>
						</div>
					</div>

					<div class="card mt-4" *ngIf="tattoo.transactions.length > 0">
						<div class="card-header">
							<h5><i class="fa fa-exchange"></i> Transactions to <a [routerLink]="['/artist', tattoo.artist.id, 'status']">{{tattoo.artist |
								artistname}}'s</a> accounts</h5>
						</div>

						<div class="card-body">
							<!--							Artist share for this tattoo is calculated at <strong>{{tattoo.artistSharePerc}}%</strong>-->
							<i *ngIf="getRate(tattoo) === 'DAY'" class="fa fa-sun-o"></i>
							<i *ngIf="getRate(tattoo) === 'NIGHT'" class="fa fa-moon-o"></i>

							<span *ngIf="getRate(tattoo) === 'SPLIT'">
								<i class="fa fa-fw fa-sun-o"></i>/<i class="fa fa-fw  fa-moon-o"></i>

							</span>
							The artist was paid out with a {{getRate(tattoo) | lowercase}} rate
							<br/><br/>

							<table class="col-8 table table-sm table-borderless">
								<tr *ngFor="let transaction of tattoo.transactions">
									<td>{{transaction.account.name}} account</td>
									<td>{{transaction.amount | currency}}</td>
									<td *ngIf="tattoo.transactions.length > 2">
										<i *ngIf="transaction.rate === 'DAY'" class="fa fa-sun-o"></i>
										<i *ngIf="transaction.rate === 'NIGHT'" class="fa fa-moon-o"></i>

										{{transaction.rate | titlecase}} rate
									</td>
								</tr>
								<tr class="font-weight-bold">
									<td>Total</td>
									<td>{{tattoo.totalArtistTransaction | currency}}</td>
								</tr>
							</table>
						</div>
						<div class="card-footer">
							<div class="row">
								<div class="col-6">
									<button class="btn btn-primary" (click)="changeArtist()"><span class="fa fa-user mr-2"></span> Modify Artist</button>
								</div>
								<div class="col-6 text-right">
									<button class="btn btn-primary" (click)="changePercentage()"><span class="fa fa-percent mr-2"></span> Modify rate
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<h5 class="text-center"><i class="fa fa-history"></i> History</h5>

		<table class="table table-striped table-sm rounded card-body">
			<tr class="p-2">
				<th class="p-2">
					Date
				</th>
				<th class="p-2">
					User
				</th>
				<th class="p-2">
					Action
				</th>
				<th class="p-2">
					Original value
				</th>
				<th class="p-2">
					New value
				</th>
			</tr>
			<tbody>
			<tr *ngFor="let log of tattoo.log">
				<td>
					{{log.created | formatdatetime}}
				</td>
				<td>
					<a [routerLink]="['/user', log.user.id]">{{log.user.name}}</a>
				</td>
				<td>
					{{(log.action| titlecase).replaceAll('_', ' ')}}
				</td>
				<td>
					{{log.originalValue}}
				</td>
				<td>
					{{log.newValue}}
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>
