<div class="card-deck mb-5 mt-3 ">
	<div class="card shadow ">
		<app-studio-performance title="Today's performance" [report]="today"></app-studio-performance>
	</div>

	<div class="card shadow">
		<app-studio-performance title="Yesterday's performance" [report]="yesterday"></app-studio-performance>
	</div>
</div>

<div class="row" *ngIf="transactions && transactions.length > 0">
	<div class="col-12">
		<div class="card shadow">
			<div class="card-header bg-gradient-info text-white">
				<h2><i class="fa fa-tag"></i> Recent discounts issued</h2>
			</div>
			<table class="card-body table table-striped table-borderless table-sm">
				<thead>
				<tr>
					<th>Date</th>
					<th>Order</th>
					<th>Studio</th>
					<th>Floormanager</th>
					<th class="text-right">Percentage</th>
					<th>Reason</th>
					<th class="text-right">Discount</th>
					<th class="text-right">Order total</th>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let transaction of transactions" [class.text-info]="transaction.comments.indexOf('100%') >= 0">
					<td style="width: 15%" [innerHTML]="transaction.created | formatdatetime"></td>
					<td style="width: 10%" [innerHTML]="transaction.order | orderPipe"></td>
					<td style="width: 15%" [innerHTML]="transaction.studio | studioPipe"></td>
					<td [innerHTML]="transaction.user | usernamePipe"></td>
					<td style="width: 10%" class="text-right" [innerHTML]="transaction.comments"></td>
					<td style="width: 20%" class="font-italic" [innerHTML]="transaction.userComments"></td>
					<td class="text-right" [innerHTML]="transaction.amount | currency:'EUR'"></td>
					<td class="text-right" [innerHTML]="transaction.order | orderValuePipe"></td>
				</tr>
				</tbody>
			</table>
			<div class="text-center mb-2">
				<a [routerLink]="['/transactions']" [queryParams]="{filters: 'method:discount', sort: 'created', order: 'DESC'}"><i
					class="fa fa-arrow-down"></i>
					See more</a><br/>
			</div>
		</div>
	</div>
</div>

