<div class="mt-3 shadow">
	<div *ngIf="artist" class="card">
		<div class="card-header text-white bg-gradient-secondary">
			<h2 class="card-title text-center" *ngIf="artist.artistType.toLowerCase() === 'tattoo'">Tattoo artist {{artist | artistname}}</h2>
			<h2 class="card-title text-center" *ngIf="artist.artistType.toLowerCase() === 'piercer'">Piercer {{artist | artistname}}</h2>
			<h2 class="card-title text-center" *ngIf="artist.artistType.toLowerCase() === 'both'">Tattoo artist & piercer {{artist | artistname}}</h2>
		</div>
		<div class="card-body">
			<br/>
			<div class="row">
				<div class="col-7 offset-1 text-left">
					<b>{{artist.firstname}} {{artist.lastname}}</b><br/>
					BSN {{artist.bsn}}<br/>
					<b *ngIf="artist.active" class="text-success">Active</b>
					<b *ngIf="!artist.active" class="text-warning">Inactive</b>
					<br/>
					<a href="mailto:{{artist.email}}">{{artist.email}}</a> <br/>
					<a href="tel:{{artist.phone}}">{{artist.phone}}</a>
				</div>
				<div class="col-3 text-right">
					<b>{{artist.commerceName}}</b><br/>
					{{artist.commerceId}} <br/>
					<div *ngIf="artist.address">
						{{artist.address.street}} {{artist.address.number}} <br/>
						{{artist.address.zip}} <br/>
						{{artist.address.city}} <br/>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="artist" class="card-header">
		<ul class="nav nav-tabs card-header-tabs">
			<li class="nav-item">
				<a class="nav-link" routerLink="status" routerLinkActive="active"><i class="fa fa-list-alt"></i> Overview</a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="artist.accounts.length > 0">
				<a class="nav-link" routerLink="reports" routerLinkActive="active"><i class="fa fa-table"></i> Earning report</a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="artist.accounts.length > 0">
				<a class="nav-link" routerLink="invoice" routerLinkActive="active"><i class="fa fa-file-o"></i> Invoice</a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="artist.accounts.length > 0">
				<a class="nav-link" routerLink="payout" routerLinkActive="active"><i class="fa fa-arrow-circle-right"></i> Payout</a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="artist.accounts.length > 0 && artist.artistType.toLowerCase() === 'tattoo' || artist.artistType.toLowerCase() === 'both'">
				<a class="nav-link" routerLink="tattoos" routerLinkActive="active"><i class="fa fa-list"></i> Tattoos</a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="artist.accounts.length > 0 && artist.artistType.toLowerCase() === 'piercer' ||
			artist.artistType.toLowerCase() === 'both'">
				<a class="nav-link" routerLink="piercings" routerLinkActive="active"><i class="fa fa-list"></i> Piercings</a>
			</li>
			<li class="nav-item" *ngIf="artist.accounts.length > 0">
				<a class="nav-link" routerLink="transactions" routerLinkActive="active"><i class="fa fa-exchange"></i> Transactions</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="documents" routerLinkActive="active"><i class="fa fa-file-o"></i> Documents</a>
			</li>
			<li class="nav-item" routerLinkActive="active" *ngIf="artist.artistType.toLowerCase() === 'tattoo' || artist.artistType.toLowerCase() === 'both'">
				<a class="nav-link" routerLink="studios" routerLinkActive="active"><i class="fa fa-building-o"></i> Studios</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" routerLink="edit" routerLinkActive="active"><i class="fa fa-address-card-o"></i> Edit artist</a>
			</li>
		</ul>
	</div>

	<div class="card-body" *ngIf="artist">
		<router-outlet></router-outlet>
	</div>
</div>
