import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Artist} from '../../../../model/domain/artist';
import {Tattoo} from '../../../../model/domain/tattoo';
import {ArtistService} from '../../../../core/service/artist.service';
import {TattooService} from '../../../../core/service/tattoo.service';

@Component({
	selector: 'app-modify-artist-tattoo',
	templateUrl: './modify-artist-tattoo.component.html',
	styleUrls: ['./modify-artist-tattoo.component.scss']
})
export class ModifyArtistTattooComponent implements OnInit {

	tattoo: Tattoo;
	artists: Artist[];
	loading = false;
	selected = 0;

	constructor(
		public activeModal: NgbActiveModal,
		private artistService: ArtistService,
		private tattooService: TattooService
	) {}

	ngOnInit(): void {
		this.artistService.getAllTattooArtists().subscribe(value => this.artists = value);
	}

	setTattoo(tattoo: Tattoo): void {
		this.tattoo = tattoo;
		this.selected = tattoo.artist.id;
	}

	canSave(): boolean {
		if (this.loading) {
			return false;
		}
		if (this.selected > 0 && this.selected !== this.tattoo.artist.id) {
			return true;
		}
		return false;
	}

	save(): void {
		this.loading = true;
		this.tattooService.changeArtist(this.tattoo, this.selected).subscribe(tattoo => this.activeModal.close(tattoo));
	}
}
