import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Listrequest} from '../../model/listrequest';
import {Paginationmodel} from '../../model/paginationmodel';
import {Tattoo} from '../../model/domain/tattoo';

@Injectable({
	providedIn: 'root'
})
export class TattooService {

	private url = 'api/tattoo/';
	private urlAll = this.url + 'all';

	constructor(
		private http: HttpClient
	) {}

	getAll(): Observable<Tattoo[]> {
		return this.http.get<Tattoo[]>(this.urlAll);
	}

	getAllTattoos(request: Listrequest): Observable<Paginationmodel> {
		return this.http.post<Paginationmodel>(`${this.url}list`, request);
	}

	changeArtist(tattoo: Tattoo, selected: number): Observable<Tattoo> {
		const model = {
			artist: selected
		};

		return this.http.post<Tattoo>(`${this.url}${tattoo.id}/changeartist`, model);
	}

	changeSharePercentage(tattoo: Tattoo, newPercentage: number): Observable<Tattoo> {
		const model = {
			newPercentage
		};

		return this.http.post<Tattoo>(`${this.url}${tattoo.id}/changepayout`, model);
	}

	changeTattooRate(tattoo: Tattoo, rate: string, startTime: number, endTime: number): Observable<Tattoo> {
		if (startTime === undefined) {
			startTime = 0;
		}
		if (endTime === undefined) {
			endTime = 0;
		}

		const model = {
			rate,
			startTime,
			endTime
		};

		return this.http.post<Tattoo>(`${this.url}${tattoo.id}/changerate`, model);
	}

	getById(id: number): Observable<Tattoo> {
		return this.http.get<Tattoo>(`${this.url}${id}/`);
	}


}
