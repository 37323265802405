import {registerLocaleData} from '@angular/common';
import * as moment from 'moment-timezone';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {NgbActiveModal, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AccountTransactionListViewComponent} from './module/artist/account-transaction-list-view/account-transaction-list-view.component';

import {AppComponent} from './app.component';
import {ArtistListComponent} from './module/artist/artist-list/artist-list.component';
import {ArtistNewComponent} from './module/artist/artist-new/artist-new.component';
import {ArtistAccountCorrectionComponent} from './module/artist/artist-view/artist-account-correction/artist-account-correction.component';
import {ArtistAccountComponent} from './module/artist/artist-view/artist-account/artist-account.component';
import {ArtistDocumentAddComponent} from './module/artist/artist-view/artist-document-add/artist-document-add.component';
import {ArtistDocumentsComponent} from './module/artist/artist-view/artist-documents/artist-documents.component';
import {ArtistEditComponent} from './module/artist/artist-view/artist-edit/artist-edit.component';
import {ArtistPayoutComponent} from './module/artist/artist-view/artist-payout/artist-payout.component';
import {ArtistReportComponent} from './module/artist/artist-view/artist-report/artist-report.component';
import {ArtistStatusComponent} from './module/artist/artist-view/artist-status/artist-status.component';
import {ArtistStudioNewComponent} from './module/artist/artist-view/artist-studio-new/artist-studio-new.component';
import {ArtistStudiosComponent} from './module/artist/artist-view/artist-studios/artist-studios.component';
import {ArtistSummaryComponent} from './module/artist/artist-view/artist-summary/artist-summary.component';
import {ArtistTransactionsComponent} from './module/artist/artist-view/artist-transactions/artist-transactions.component';
import {ArtistViewComponent} from './module/artist/artist-view/artist-view.component';
import {ArtistResolver} from './module/artist/artist-view/artist.resolver';
import {ArtistViewService} from './module/artist/artist-view/artist.view.service';
import {CustomDateParserFormatter} from './conf/datepicker-adapter';
import {DashboardComponent} from './module/dashboard/dashboard/dashboard.component';
import {FiledropDirective} from './component/filedrop.directive';
import {FileSizePipe} from './core/pipe/filesize.pipe';
import {FormControllerModule} from './component/form-controller/form-controller.module';
import {ErrorInterceptor} from './core/interceptor/error.interceptor';
import {JwtInterceptor} from './core/interceptor/jwt.interceptor';
import {LoginComponent} from './module/auth/login/login.component';
import {LogoutComponent} from './module/auth/logout/logout.component';
import {NavigatorComponent} from './core/navigator/navigator.component';
import {OrderListComponent} from './module/order/order-list/order-list.component';
import {OrderViewComponent} from './module/order/order-view/order-view.component';
import {OrderResolver} from './module/order/order-view/order.resolver';
import {PiercingListComponent} from './module/piercing/piercing-list/piercing-list.component';
import {PiercingViewComponent} from './module/piercing/piercing-view/piercing-view.component';
import {PiercingResolver} from './module/piercing/piercing-view/piercing.resolver';
import {AccountPipe} from './core/pipe/account.pipe';
import {BooleanLightPipe} from './core/pipe/boolean-light.pipe';
import {EnumTypePipe} from './core/pipe/enum-type.pipe';
import {PipeModule} from './core/pipe/pipe.module';
import {PiercingEditComponent} from './module/pos/pos-piercing-list/piercing-edit/piercing-edit.component';
import {PosPiercingListComponent} from './module/pos/pos-piercing-list/pos-piercing-list.component';
import {ProductEditComponent} from './module/pos/product-list/product-edit/product-edit.component';
import {ProductListComponent} from './module/pos/product-list/product-list.component';
import {ReportMonthComponent} from './module/report/report-month/report-month.component';
import {ReportStudioPerfomanceComponent} from './module/report/report-studio-perfomance/report-studio-perfomance.component';
import {AppRoutingModule} from './core/routing/app-routing.module';
import {TableStudioFilterComponent} from './component/table-view/filters/table-studio-filter/table-studio-filter.component';
import {TableViewModule} from './component/table-view/table-view.module';
import {TattooListComponent} from './module/tattoo/tattoo-list/tattoo-list.component';
import {ModifyShareTattooComponent} from './module/tattoo/tattoo-view/modify-share-tattoo/modify-share-tattoo.component';
import {TattooViewComponent} from './module/tattoo/tattoo-view/tattoo-view.component';
import {TattooResolver} from './module/tattoo/tattoo-view/tattoo.resolver';
import {UserListComponent} from './module/user/user-list/user-list.component';
import {UserNewComponent} from './module/user/user-new/user-new.component';
import {UserEditComponent} from './module/user/user-view/user-edit/user-edit.component';
import {UserOverviewComponent} from './module/user/user-view/user-overview/user-overview.component';
import {UserViewComponent} from './module/user/user-view/user-view.component';
import {UserResolver} from './module/user/user-view/user.resolver';
import {UserViewService} from './module/user/user-view/user.view.service';
import {ModifySharePiercingComponent} from './module/piercing/piercing-view/modify-share-piercing/modify-share-piercing.component';
import {ArtistTattoosComponent} from './module/artist/artist-view/artist-tattoos/artist-tattoos.component';
import {ArtistPiercingsComponent} from './module/artist/artist-view/artist-piercings/artist-piercings.component';
import {StudioDayReviewComponent} from './module/report/studio-overview/studio-day-review.component';
import {GiftcardListComponent} from './module/giftcard-list/giftcard-list.component';
import {MultiArtistPayoutComponent} from './module/multi-artist-payout/multi-artist-payout.component';
import {ModifyArtistTattooComponent} from './module/tattoo/tattoo-view/modify-artist-tattoo/modify-artist-tattoo.component';
import {ModifyArtistPiercingComponent} from './module/piercing/piercing-view/modify-artist-piercing/modify-artist-piercing.component';
import {ConfirmationDialogComponent} from './component/confirmation-dialog/confirmation-dialog.component';
import {OrderTransactionListComponent} from './module/transaction/order-transaction-list/order-transaction-list.component';
import {StudioPerformanceComponent} from './module/dashboard/dashboard/studio-performance/studio-performance.component';
import {OrderCancelComponent} from './module/order/order-view/order-cancel/order-cancel.component';
import {ImageViewModalComponent} from './module/artist/artist-view/artist-documents/image-view-modal/image-view-modal.component';
import {OrderNewComponent} from './module/order/order-new/order-new.component';
import {OrderAddProductComponent} from './module/order/order-new/order-add-product/order-add-product.component';
import {OrderAddTattooComponent} from './module/order/order-new/order-add-tattoo/order-add-tattoo.component';
import {OrderAddPiercingComponent} from './module/order/order-new/order-add-piercing/order-add-piercing.component';
import {OrderAddPaymentComponent} from './module/order/order-new/order-add-payment/order-add-payment.component';
import {OrderAddDiscountComponent} from './module/order/order-new/order-add-discount/order-add-discount.component';
import {ArtistInvoiceComponent} from './module/artist/artist-view/artist-invoice/artist-invoice.component';
import {ReportSalesComponent} from './module/report/report-sales/report-sales.component';
import {InventoryComponent} from './module/inventory/inventory/inventory.component';
import {InventoryDashboardComponent} from './module/inventory/inventory-dashboard/inventory-dashboard.component';
import {InventoryViewTransactionsComponent} from './module/inventory/inventory-view-transactions/inventory-view-transactions.component';
import {InventoryTransferComponent} from './module/inventory/inventory-transfer/inventory-transfer.component';
import {InventoryTransactionsComponent} from './module/inventory/inventory-transactions/inventory-transactions.component';
import {InventoryItemLimitsComponent} from './module/inventory/inventory/inventory-item-limits/inventory-item-limits.component';
import { InventoryItemCorrectionComponent } from './module/inventory/inventory/inventory-item-correction/inventory-item-correction.component';
import { PageComponent } from './component/page/page.component';

registerLocaleData(localeNl, 'nl');

@NgModule({
	declarations: [
		AppComponent,
		ArtistListComponent,
		DashboardComponent,
		ArtistViewComponent,
		AccountTransactionListViewComponent,
		ArtistSummaryComponent,
		ArtistEditComponent,
		ArtistReportComponent,
		ArtistStudiosComponent,
		UserListComponent,
		OrderListComponent,
		TattooListComponent,
		UserViewComponent,
		OrderViewComponent,
		TattooViewComponent,
		ArtistDocumentAddComponent,
		ArtistDocumentsComponent,
		FileSizePipe,
		PosPiercingListComponent,
		ProductListComponent,
		FiledropDirective,
		ReportMonthComponent,
		ArtistTransactionsComponent,
		ArtistAccountComponent,
		ArtistPayoutComponent,
		ArtistAccountCorrectionComponent,
		AccountPipe,
		LoginComponent,
		LogoutComponent,
		NavigatorComponent,
		ArtistNewComponent,
		ArtistStatusComponent,
		UserEditComponent,
		UserNewComponent,
		UserOverviewComponent,
		ArtistStudioNewComponent,
		ProductEditComponent,
		EnumTypePipe,
		BooleanLightPipe,
		PiercingEditComponent,
		ModifyShareTattooComponent,
		PiercingListComponent,
		PiercingViewComponent,
		ModifySharePiercingComponent,
		ArtistTattoosComponent,
		ArtistPiercingsComponent,
		StudioDayReviewComponent,
		ReportStudioPerfomanceComponent,
		GiftcardListComponent,
		MultiArtistPayoutComponent,
		ModifyArtistTattooComponent,
		ModifyArtistPiercingComponent,
		ConfirmationDialogComponent,
		OrderTransactionListComponent,
		StudioPerformanceComponent,
		ReportStudioPerfomanceComponent,
		OrderCancelComponent,
		ImageViewModalComponent,
		OrderNewComponent,
		OrderAddProductComponent,
		OrderAddTattooComponent,
		OrderAddPiercingComponent,
		OrderAddPaymentComponent,
		OrderAddDiscountComponent,
		ArtistInvoiceComponent,
		ReportSalesComponent,
		InventoryComponent,
		InventoryDashboardComponent,
		InventoryViewTransactionsComponent,
		InventoryTransferComponent,
		InventoryTransactionsComponent,
		InventoryItemLimitsComponent,
  InventoryItemCorrectionComponent,
  PageComponent,
	],
	imports: [
		BrowserModule,
		NgbModule,
		HttpClientModule,
		AppRoutingModule,
		FormsModule,
		TableViewModule,
		ReactiveFormsModule,
		PipeModule,
		FormControllerModule,
	],
	providers: [
		{provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
		{provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		ArtistViewService,
		PiercingResolver,
		UserViewService,
		ArtistResolver,
		UserResolver,
		OrderResolver,
		TattooResolver,
		NgbActiveModal,
		{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
	],
	exports: [],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		moment.tz.setDefault('Europe/Amsterdam');
	}
}
